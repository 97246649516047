import dayjs from 'dayjs';
import Cookies from 'js-cookie';
import { Message } from 'element-ui';
import Vue from 'vue';
import { useBasic } from '@speedshop/sdk-api';
import { getAccessTokenByCode } from '@/graphql/common.gql';
import Logout from '@/utils/logout.js';

const API_URL = {
  code: 'https://cnbs-feature-api-dev.gtech.asia/graphql',
  dev: 'https://cnbs-feature-api-dev.gtech.asia/graphql',
  test: 'https://cnbs-api-stg.underarmour.cn/graphql/',
  'ua-staging': 'https://cnbs-api.underarmour.cn/graphql',
  pro: 'https://cnbs-api.underarmour.cn/graphql',
  'prod-id': 'https://cnbs-api.underarmour.cn/graphql',
  'ua-prod': 'https://cnbs-api.underarmour.cn/graphql'
};

// 白名单的code 不全局报error
const whiteCodeListWithNoErrorMessage = [
  'mfa.validate.code.null',
  'email.verify.code.expired', // 邮箱校验过期不需要全局报错 做了特殊处理让客户重试
  '100210047', // 未设置密码
  '10143310', // 优惠码错误
  '20220319', // 邮箱验证校验码无效
  '10143310', // 优惠码无效
  '01313121', // 换货路由试算失败
  '101313122', // 换出商品库存不足
  '3016', // ffs 没有绑定邮箱
  '3008', // ffs 找不到相关活动
  '3013', // ffs 活动尚未开始
  '3012', // ffs 活动已结束
  '3004', // ffs 优惠码无效
  '10021001', // 账号或者密码不正确
  'account_lockout_error', // 账号锁定
  'UA_login_error_count', // 错误次数倒计次数
  '20220318', // 无效的校验码 - 登录
  '20220317', // 无效的校验码 - 重置密码
  'idm.change-password-failed' // 原始密码和用户输入的不符
];

// 在当前页面过期需跳转首页页
export const REDIRECT_LOGIN = [
  'Account',
  'PersonalCenterIndex',
  'accountSetting',
  'accountSettingLoginInfo',
  'accountSettingLogoutEmail',
  'accountSettingPersonInfo',
  'accountSettingLogout',
  'address',
  'address-old',
  'Order',
  'orderDetails',
  'refundList',
  'ReturnDetails',
  'coupons',
  'wishlist',
  'memberRights',
  'checkout',
  'paySuccess',
  'payAgain',
  'pay-transfer'
];
const PRO_ENV = process.env.PRO_ENV;
const orgCodeWithEnv = {
  dev: '10000443',
  'ua-staging': '10000443',
  'ua-prod': '150000001'
};

const WarningError = ['stuffyBag_1001'];

export const isCouponError = code => {
  if (!code) return false;
  return (
    ['10142988', '10300502', '10143310', 'giveaway_100'].includes(code) || code.startsWith('1014')
  );
};

const isWarningError = code => {
  return WarningError.includes(code) || isCouponError(code);
};

export default {
  mobileScreenSize: 1024,
  // set your application language
  // TODO:Using config data to set the language of application
  language: {
    list: ['en', 'id'],
    default: 'en',
    switch: true
  },
  // Define the function of format  price of products , address , date.
  format: {
    address(value) {
      const { stateName = '', cityName = '', districtName = '', zipCode = '' } = value || {};
      return `${districtName},${cityName},${stateName} ${zipCode}`;
    },

    date(value) {
      const dateFormat = process.env.dateFormat || 'DD MMMM YYYY | HH:mm:ss';
      value = String(value);
      if (value === '' || !value) {
        return '';
      }
      return dayjs(value).format(dateFormat);
    },

    price(money) {
      const env = process.env;
      const {
        currency = '¥',
        currencyPosition = 'LEFT',
        toFixed = 2,
        currencySeparator = ','
      } = env;

      const fixedNum = Number(toFixed) || 0;
      const sign = currencySeparator;
      // eslint-disable-next-line no-useless-escape
      money = `${parseFloat(`${money || 0}`.replace(/[^\d\.-]/g, '')).toFixed(fixedNum)}`;
      const moneyStart = money
        .split('.')[0]
        // eslint-disable-next-line no-useless-escape
        .toString()
        .replace(/(\d)(?=(?:\d{3})+$)/g, `$1${sign}`);

      const dotValue = Number(money).toFixed(fixedNum).split('.')[1];
      let computedMoney = '0';
      // id
      if (sign === '.') {
        computedMoney = `${moneyStart},${dotValue}`;
      }
      // other
      if (sign === ',') {
        computedMoney = `${moneyStart}.${dotValue}`;
      }

      if (!dotValue) {
        computedMoney = moneyStart;
      }

      return currencyPosition === 'RIGHT'
        ? `${computedMoney} ${currency}`
        : `${currency} ${computedMoney}`;
    }
  },
  // Request api url of the different environment
  // We match Api-url by environment variable  , please make sure your environment has a variable  PRO_ENV,
  APIURL: {
    ...API_URL
  },
  SITEURL: {
    code: 'https://cnbs.underarmour.cn/',
    dev: 'https://cnbs.underarmour.cn/',
    dev1: 'https://cnbs.underarmour.cn/',
    'ua-staging': 'https://cnbs.underarmour.cn/',
    pro: 'https://cnbs.underarmour.cn/',
    'prod-id': 'https://cnbs.underarmour.cn/',
    'ua-prod': 'https://cnbs.underarmour.cn/'
  },
  siteCode: 'UA-CNBS-MALL',
  // org stg
  // orgCode: '10000443',
  // org uat
  orgCode: '150000001',

  graphql: {
    errorHandler({ message, code, app, isServer, redirect, response }) {
      const {
        currentRoute: { fullPath, name }
      } = app.router;
      console.log(app.router);

      let path = '/';
      // let path = '/login?redirectUrl=' + encodeURIComponent(fullPath);

      if (name === 'Logout') {
        path = '/';
      }

      if (isServer) {
        if (code === '0002') {
          // 过期时候在需登录的页面  例如：我的订单
          if (REDIRECT_LOGIN.includes(name)) {
            // app.$cookies.remove('token');
            redirect('/');
          }
          return Promise.reject(code);
        } else {
          return Promise.reject(code);
        }
      } else if (code === '0002') {
        const logoutInstance = new Logout({ app, store: null, currentRouteName: name });
        logoutInstance.clearLocalStorage();
        logoutInstance.clearAllCookie();
        logoutInstance.goLoginPage(name);
      } else if (whiteCodeListWithNoErrorMessage.includes(code)) {
        return Promise.reject(code);
      } else {
        // 网络异常： 链接超时   graphql&Java异常抛错
        // 10151028: "Inconsistent requests"
        const NETWORK_ERRORS = ['10151028', 'unknown.exception'];
        if (
          message === '' ||
          !message ||
          (response.errors &&
            response.errors.length > 0 &&
            response.errors[0].code &&
            NETWORK_ERRORS.includes(response.errors[0].code))
          // ||
          // (response.errors &&
          //   response.errors[0].locations &&
          //   response.errors[0].locations.length > 0)
        ) {
          // Message({
          //   type: 'error',
          //   message: '系统异常,请稍后重试'
          // });
        } else {
          Message({
            type: isWarningError(code) ? 'warning' : 'error',
            message
          });
        }
      }
    }
  },
  // here is root store
  store: {
    // Add state here
    state: {
      showLogin: false,
      headerList: [],
      adData: null,
      ddCodes: {}, // Vue插件 ddQuery 需要用到的，用以保存请求到的数据字典
      pageScrollY: 0, // 页面Y轴的滚动距离
      miniShoppingCart: {}, // mini 购物车开关
      addressDrawerFlag: false, // 地址抽屉 的开关
      filterSideIsSticky: false // 搜索栏是否已吸顶
    },
    actions: {
      // Add your code here , it is executing while the server is initialized
      nuxtServerInit({ commit }, { app }) {
        //
        app.$cookies.set('gt-local', 'zh-CN');
        commit('setProENV', process.env.PRO_ENV);

        if (process.env.NODE_ENV === 'development') {
          // 本地开发使用的 API
          const url =
            process.env.__GTECH_VARIABLES_GRAPHQL_URL__ ||
            'https://cnbs-api.underarmour.cn/graphql';
          commit('setAPIURI', url);
        } else {
          const url =
            process.env.__GTECH_VARIABLES_GRAPHQL_URL__ ||
            'https://cnbs-api.underarmour.cn/graphql';
          commit('setAPIURI', url);
        }
      },
      // Add your code here , it is executing while the client is initialized
      async nuxtClientInit({ state, commit }) {
        //
        localStorage.setItem('gt-local', 'zh-CN');
        if (process.env.NODE_ENV === 'development') {
          // commit('setAPIURI', 'http://localhost:3000/graphql');
        }

        try {
          const ua = navigator.userAgent.toLowerCase();
          const isInWechat = ua.match(/MicroMessenger/i) == 'micromessenger';
          const href = window.location.href;
          const isInRedirectUrl = href.includes('code') && href.includes('state');

          if (isInWechat && !localStorage.getItem('openid') && !isInRedirectUrl) {
            window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx95a7a5c2f9020dd3&redirect_uri=${window.location.href}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`;
          } else if (isInWechat && isInRedirectUrl) {
            if (localStorage.getItem('openid')) {
              return;
            }
            const query = href.split('?')[1];
            const queryList = query.split('&');
            const queryListMap = {};
            queryList.forEach(item => {
              const key = item.split('=')[0];
              const value = item.split('=')[1];
              queryListMap[key] = value;
            });

            const code = queryListMap.code;
            const Basic = useBasic();

            const res = await Basic.gqlClient.query(getAccessTokenByCode, {
              code
            });
            const {
              data: {
                data: { openid }
              }
            } = res;
            openid && localStorage.setItem('openid', openid);
          }
        } catch (e) {
          //
          console.log(e);
        }
      }
    },
    getters: {
      // 用户是否完善过信息
      isUserCompletedInfo(state) {
        return !!(state.accountInfo && state.accountInfo.firstName && state.accountInfo.birthDate);
      }
    },
    mutations: {
      setShowLogin(state, data) {
        state.showLogin = data;
      },
      setHeaderList(state, data) {
        console.log('setHeaderList', data);
        state.headerList = data;
      },
      setAdData(state, data) {
        state.adData = data;
      },
      UPDATE_DDCODE(state, { name, value }) {
        Vue.set(state.ddCodes, name, value);
      },
      CLEAR_DDCOED(state) {
        Object.keys(state.ddCodes).forEach(key => {
          Vue.set(state.ddCodes, key, key.endsWith('_MAP') ? {} : []);
        });
      },
      setProENV(state, data) {
        state.PRO_ENV = data;
      },
      UPDATE_PAGE_SCROLL_Y(state, data) {
        state.pageScrollY = data;
      },
      UPDATE_MINI_SHAPPING(state, data) {
        state.miniShoppingCart = data;
      },
      UPDATE_ADDRESS_DRAWER(state, data) {
        state.addressDrawerFlag = data;
      },
      setFilterSideStickyStataus(state, data) {
        state.filterSideIsSticky = data;
      }
    }
  }
};
